import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Blogs from "../components/Blogs"
// ...GatsbyImageSharpFluid

const Blog = ({ data: { allStrapiProject: { nodes: blogs } } }) => {
  return <Layout>
    <section className="blog-page">
      <Blogs blogs={blogs} title="blog" />
    </section>
  </Layout>
}

export const query = graphql`
  query {
    allStrapiProject (filter: {featured: {eq: true}}) {
      nodes {
        id
        github
        title
        description
        featured
        url
        slug
        stack {
        id
        title
       }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData 
            }
          }
        }
      }
    }
  }`

export default Blog
